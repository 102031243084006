import { Dispatch, SetStateAction } from 'react'

export type ID = undefined | null | number

export type PaginationState = {
    page: number
    items_per_page: 10 | 30 | 50 | 100
    links?: Array<{ label: string; active: boolean; url: string | null; page: number | null }>
}

export type SortState = {
    sort?: string
    order?: 'asc' | 'desc'
}

export type FilterState = {
    filter?: {
        agent?: string
        payment_type?: string
        start_date?: string
        end_date?: string
        seller?: string
    }
}

export type SearchState = {
    search?: string
}

export type Response<T> = {
    data?: T
    msg?: string
    total?: number
    payload?: {
        pagination?: PaginationState
    }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState
// export type QueryState = FilterState & SearchState

export type QueryRequestContextProps = {
    state: QueryState
    updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
    page: 1,
    items_per_page: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
    state: initialQueryState,
    updateState: () => { },
}

export type QueryResponseContextProps<T> = {
    response?: Response<Array<T>> | undefined
    refetch: () => void
    isLoading: boolean
    query: string
}

export const initialQueryResponse = { refetch: () => { }, isLoading: false, query: '' }

export type ListViewContextProps = {
    selected: Array<ID>
    onSelect: (selectedId: ID) => void
    onSelectAll: () => void
    clearSelected: () => void
    // NULL => (CREATION MODE) | MODAL IS OPENED
    // NUMBER => (EDIT MODE) | MODAL IS OPENED
    // UNDEFINED => MODAL IS CLOSED
    itemForUpdate?: any
    setItemForUpdate: Dispatch<SetStateAction<any>>
    isAllSelected: boolean
    disabled: boolean

    itemForDelete?: any
    setItemForDelete: Dispatch<SetStateAction<any>>

    itemForPause?: any
    setItemForPause?: Dispatch<SetStateAction<any>>

    isClearBalance?: boolean
    setIsClearBalance?: Dispatch<SetStateAction<boolean>>

    isSendBalanceMessage?: boolean
    setIsSendBalanceMessage?: Dispatch<SetStateAction<boolean>>

    isSendSpecificMessage?: boolean
    setIsSendSpecificMessage?: Dispatch<SetStateAction<boolean>>

    itemFormRemind?: any
    setItemForRemind?: Dispatch<SetStateAction<any>>

    itemForAccount?: any
    setItemForAccount?: Dispatch<SetStateAction<any>>

    isFilter?: any
    setIsFilter?: Dispatch<SetStateAction<any>>

    arrayItems?: Array<string>
    setArrayItems?: Dispatch<SetStateAction<Array<string>>>

    isMove?: boolean
    setIsMove?: Dispatch<SetStateAction<boolean>>

    isBulkBalance?: boolean
    setIsBulkBalance?: Dispatch<SetStateAction<boolean>>

    isDelSellers?: boolean
    setIsDelSellers?: Dispatch<SetStateAction<boolean>>

    isDelBuyers?: boolean
    setIsDelBuyers?: Dispatch<SetStateAction<boolean>>

    isTransactionAdd?: boolean
    setIsTransactionAdd?: Dispatch<SetStateAction<boolean>>

    itemForDeleteReminder?: any
    setItemForDeleteReminder?: Dispatch<SetStateAction<any>>

    isToolModal?: boolean
    setIsToolModal?: Dispatch<SetStateAction<boolean>>

    isResetTimer?: boolean
    setIsResetTimer?: Dispatch<SetStateAction<boolean>>
}

export const initialListView: ListViewContextProps = {
    selected: [],
    onSelect: () => { },
    onSelectAll: () => { },
    clearSelected: () => { },
    setItemForUpdate: () => { },
    isAllSelected: false,
    disabled: false,

    setItemForDelete: () => { },

    isClearBalance: false,
    setIsClearBalance: () => { },

    isSendBalanceMessage: false,
    setIsSendBalanceMessage: () => { },

    isSendSpecificMessage: false,
    setIsSendSpecificMessage: () => { },

    setItemForRemind: () => { },
}
